import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MailIcon from '@mui/icons-material/Mail';

function Header() {
    return (
        <Grid container direction='column'>
            <Grid item sx={{height: '20px'}} />
            <Grid item sx={{height: '80px'}}>
                <Grid container direction='row'>
                    <Grid item sx={{width: '50px', marginRight: 'auto'}}>
                        <Box className='title-left' />
                    </Grid>
                    <Grid item xs>
                        <Box className='title-text' />
                    </Grid>
                    <Grid item sx={{width: '70px', marginLeft: 'auto'}}>
                        <Box className='title-right' />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className='title-bar'>
                <Button className='contactus' endIcon={<MailIcon />} href={`mailto:office@arpeto.com`}>
                    Contact Us
                </Button>
            </Grid>
        </Grid>
    );
}

export default Header;