import MainContent from "./components/MainContent";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

function App() {
    return (
        <Box className='base-background'>
            {/* Header */}
            <Grid zIndex={4} container direction='column' sx={{height: '140px', position: 'absolute'}}>
                <Grid item sx={{height: '140px'}}>
                    <Header />
                </Grid>
            </Grid>

            {/* Content Layer */}
            <Grid zIndex={3} container direction='column' sx={{height: '100vh', position: 'absolute'}}>
                <Grid item xs>
                    <MainContent />
                </Grid>
            </Grid>

            {/* Footer */}
            <Grid zIndex={2} container direction='column' sx={{height: '100vh', position: 'absolute'}}>
                <Grid item xs>
                    <Footer />
                </Grid>
            </Grid>

            {/* Star Layer */}
            <Grid zIndex={1} container direction='column' sx={{height: '100vh', position: 'absolute'}}>
                <Grid item xs className='starfield' />
            </Grid>
        </Box>
    );
}

export default App;