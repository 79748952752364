import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Zoom from '@mui/material/Zoom';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom
        in={true}
        easing={{
            enter: "cubic-bezier(0, 2.5, .8, 2)",
            exit: "linear"
        }}
        ref={ref}
        {...props}
    />;
});

function MainContent() {
    // React State
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [dialogContent, setDialogContent] = React.useState('');

    // Functions
    const posterClick = (event) => {
        switch (event.currentTarget.id) {
            case 'art':
                setDialogTitle('Art / Software Services');
                setDialogContent(
                    <div className='dialogContent'>
                        <p>Arpeto provides a variety of services in the art and software space.</p>
                        <p>Art in both digital and real world artifacts is done on a comission basis.</p>
                        <p>Software services include Web Development, User Interface, Graphics and Design. Additional consulting services around the various process and management of software development are also available.</p>
                        <p>Please <a href='mailto:office@arpeto.com'>contact us</a> for more information.</p>
                    </div>
                );
                break;

            case 'pet':
                setDialogTitle('Pet Services');
                setDialogContent(
                    <div className='dialogContent'>
                        <p>Arpeto provides pet sitting and walking services.</p>
                        <p>While no one can love pets more than their owners, we do our best to come in at a close second.</p>
                        <p>Services include scheduled walks, in-house visits and general care while you are away.</p>
                        <p>Please <a href='mailto:office@arpeto.com'>contact us</a> for more information.</p>
                    </div>
                );
                break;
    
            case 'food':
                setDialogTitle('Food Media');
                setDialogContent(
                    <div className='dialogContent'>
                        <p>Arpeto media services extend to our food videos, specifically in the baking arena.</p>
                        <p>Our videos aim to be fun and informative.</p>
                        <p>Check us out today here on YouTube.</p>
                    </div>
                );
                break;
        
            default:
                setDialogTitle('Whoopsies');
                break;
        }
        
        setDialogOpen(true);
    }

    const posterClose = () => {
        setDialogOpen(false);
    };

    return (
        <Grid container direction='row' sx={{height: '100%', width: '100%', paddingTop: '240px'}}>
            <Grid item xs>
                <Box className='posterBase' sx={{height: '300px', width: '250px'}} onClick={posterClick} id='art'>
                    <Box className='paint-brush' sx={{height: '300px', width: '250px'}} />
                </Box>
            </Grid>
            <Grid item xs>
                <Box className='posterBase' sx={{height: '300px', width: '250px'}} onClick={posterClick} id='pet'>
                    <Box className='whippet' sx={{height: '300px', width: '250px'}} />
                </Box>
            </Grid>
            <Grid item xs>
                <Box className='posterBase' sx={{height: '300px', width: '250px'}} onClick={posterClick} id='food'>
                    <Box className='cake' sx={{height: '300px', width: '250px'}} />
                </Box>
            </Grid>
            <Dialog
                open={dialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={posterClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        backgroundImage: 'url("/assets/dialog-frame.svg")',
                        backgroundColor: 'transparent',
                        backgroundRepeat: 'no-repeat',
                        boxShadow: 'none'
                    },
                }}
                maxWidth='xs'
            >
                <DialogTitle sx={{marginTop: '5px', marginBottom: '0px', paddingBottom: '0px'}}><div className='dialogTitle'>{dialogTitle}</div></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {dialogContent}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Grid>
    );
}

export default MainContent;