import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css';
import './components/Header.css';
import './components/Footer.css';
import './components/MainContent.css';
import App from './App';

ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
    document.getElementById('root')
);