import Box from '@mui/material/Box';

function Footer() {
    const year =  new Date().getFullYear();

    return (
        <Box sx={{height: '100%', width: '100%', position: 'relative'}}>
            {/* Mountains */}
            <Box className='mountain-left' sx={{height: '160px', width: '380px', zIndex: 1}} />
            <Box className='mountain-center' sx={{height: '160px', width: '380px', zIndex: 2}}>
                <Box sx={{color: '#333', textAlign: 'center', paddingTop: '130px'}}>
                    <small>© {year} Bleu Fox Bakery, LLC</small>
                </Box>
            </Box>
            <Box className='mountain-right' sx={{height: '160px', width: '380px', zIndex: 3}} />

            {/* Drive-In Screen */}
            <Box className='drive-in-screen' sx={{height: '160px', width: '380px', zIndex: 4}} />

            {/* Car */}
            <Box className='car1' sx={{height: '160px', width: '380px', zIndex: 5}} />

            {/* Drive-In Sign */}
            <Box className='drive-in-sign' sx={{height: '160px', width: '380px', zIndex: 6}} />

            {/* Car */}
            <Box className='car2' sx={{height: '160px', width: '380px', zIndex: 7}} />
        </Box>
    );
}

export default Footer;